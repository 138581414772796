import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { BatchTypes, DataItemResponse } from './BatchService.types'

const initstage: BatchTypes = {
    BatchLoading: false,
    BatchData: null,
    BatchError: null,

    postBatchLoading: false,
    postBatchData: null,
    postBatchDataError: null,
}

const BatchSlice = createSlice({
    name: 'BatchSlice',
    initialState: initstage,
    reducers: {
        getBatchData(state, action: PayloadAction<number>) {
            state.BatchLoading = true
            state.BatchError = null
        },
        getBatchSuccess(state, action: PayloadAction<DataItemResponse>) {
            state.BatchData = action.payload
            state.BatchLoading = false
            state.BatchError = null
        },
        getBatchFailure(state, action: PayloadAction<any>) {
            state.BatchLoading = false
            state.BatchError = action.payload
        },

        postBatchData(state, action: PayloadAction) {
            state.postBatchLoading = true
            state.postBatchDataError = null
        },
        postBatchSuccess(state, action: PayloadAction<any>) {
            
            state.postBatchData = action.payload
            state.postBatchLoading = false
            state.postBatchDataError = null
        },
        postBatchFailure(state, action: PayloadAction<any>) {
            state.postBatchLoading = false
            state.postBatchDataError = action.payload
        },
    },
})
export const { getBatchData, getBatchSuccess, getBatchFailure, postBatchData, postBatchSuccess, postBatchFailure } =
    BatchSlice.actions
export default BatchSlice.reducer
