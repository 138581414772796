import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { combineReducers } from 'redux'

// import Reactotron from '@config/Reactotron.config'
import rootSaga from '@store/saga'
import authenticationReducer from '@authentication/store/authentication.slice'
import PlanningStatsReducer from '../modules/dashboard/store/planningStats/PlanningStats.slice'
import PlanningOverviewReducer from '../modules/dashboard/store/planningOverview/PlanningOverview.slice'
import ExcelReducer from '../modules/dashboard/store/excelFile/ExcelFile.slice'
import BatchReducer from '../modules/dashboard/store/BatchService/BatchService.slice'
import ParameterReducer from '../modules/dashboard/store/Parameters/Parameters.slice'
import BatchUpdateReducer from '../modules/dashboard/store/BatchUpdateService/BatchUpdateService.slice'
import GeneralReducer from '../store/generalStore/general.slice'

import versionsReducer from '../modules/dashboard/store/versions/Versions.slice'

// const NODE_ENV = process.env.NODE_ENV
const sagaMiddleware = createSagaMiddleware()

const middlewares: any[] = [sagaMiddleware]

const reducers = combineReducers({
    authentication: authenticationReducer,
    PlanningStats: PlanningStatsReducer,
    PlanningOverview: PlanningOverviewReducer,
    ExcelData: ExcelReducer,
    BatchData: BatchReducer,
    versionsData: versionsReducer,
    ParametersData: ParameterReducer,
    generalActionData: GeneralReducer,
    BatchUpdate: BatchUpdateReducer,
})

export const store = configureStore({
    reducer: reducers,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: middlewares,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    // enhancers: NODE_ENV === 'development' ? [Reactotron.createEnhancer!()] : undefined,
})

sagaMiddleware.run(rootSaga)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
