import { GET } from '@api/ApiService'
const BASE_URL = process.env.REACT_APP_BASE_API_URL

const urlFactory = {
    getExcelData: (batchId: number) => `${BASE_URL}/api/SIMI/Batch/${batchId}/ExportSIMIExcel`,
    getKameleonExcelData: (batchId: number) => `${BASE_URL}/api/SIMI/Batch/${batchId}/ExportKameleonExcel`,
    getStrookExcelData: (batchId: number) => `${BASE_URL}/api/SIMI/Batch/${batchId}/ExportKameleonStrookExcel`,
}

const ExcelService = {
    getExcelData: async (batchId: number) => {
        return GET(urlFactory.getExcelData(batchId))
    },
    getKameleonData: async (batchId: number) => {
        return GET(urlFactory.getKameleonExcelData(batchId))
    },
    getStrookData: async (batchId: number) => {
        return GET(urlFactory.getStrookExcelData(batchId))
    },
}

export default ExcelService
