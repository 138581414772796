import { GET } from '@api/ApiService'
import { ParametersParams } from '@dashboard/store/Parameters/Parameters.types'
const BASE_URL = process.env.REACT_APP_BASE_API_URL
// const BASE_URL = "https://nsldev.nimble.expert/ZwanenburgAPI"
// const BASE_URL = "https://nsldev.nimble.expert/VVAPI"

const urlFactory = {
    ParametersData: (sendParams:ParametersParams) => `${BASE_URL}/api/SIMI/Batch/${sendParams.batchId}/Parameters`
}

const ParametersService = {
    ParametersData: async (sendParams:ParametersParams) => {
        return GET(urlFactory.ParametersData(sendParams));
    },

}

export default ParametersService