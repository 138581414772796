import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { VersionsTypes, VersionsParams } from './Versions.types'
import { VersionsResponse } from '@app-types/VersionsResponse.types'

const initstage: VersionsTypes = {
    VersionsLoading: false,
    VersionsData: null,
    VersionsError: null,
}

const VersionsSlice = createSlice({
    name: 'VersionsSlice',
    initialState: initstage,
    reducers: {
        getVersionsData(state, action: PayloadAction<VersionsParams>) {
            state.VersionsLoading = true
            state.VersionsError = null
        },
        getVersionsSuccess(state, action: PayloadAction<VersionsResponse[]>) {
            state.VersionsData = action.payload
            state.VersionsLoading = false
            state.VersionsError = null
        },
        getVersionsFailure(state, action: PayloadAction<any>) {},
    },
})
export const { getVersionsData, getVersionsSuccess, getVersionsFailure } = VersionsSlice.actions
export default VersionsSlice.reducer
