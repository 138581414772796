import React, { useState, useMemo, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { lighten } from 'polished'
import CloseIcon from '@assets/images/close.svg'
import homeIcon from '@assets/images/homeLogo.svg'
import kameleon from '@assets/images/menu/kameleon.svg'

interface MainMenuProps {
    open: boolean
    mobileMenuClick?: () => void
    isMobile?: boolean
}

const MainMenu: React.FC<MainMenuProps> = ({ open, mobileMenuClick, isMobile }) => {
    const { simi } = useParams()

    const MenuItems = [
        {
            label: 'SIMI',
            icon: homeIcon,
            route: `/${'simi'}`,
        },
        {
            label: 'KAMELEON',
            icon: kameleon,
            route: `/${'kameleon'}`,
        },
    ]

    const navigate = useNavigate()

    const [selectedMenu, setSelectedMenu] = useState('SIMI')

    useEffect(() => {
        if (simi) {
            setSelectedMenu(simi === 'simi' ? 'SIMI' : 'KAMELEON')
        }
    }, [simi])

    const handleClickMenuItem = ({ label, route }: any): void => {
        setSelectedMenu(label)
        mobileMenuClick?.()
        if (route) {
            navigate(route, { replace: true })
        }
        if (label === 'Log out') {
            navigate('/login', { replace: true })
            localStorage.removeItem('console_admin_token')
        }
    }

    const menus = useMemo(() => {
        const MobileMenuItem = MenuItems.filter((item) => item.label !== 'Logs')

        return isMobile ? [...MobileMenuItem, { label: 'Log out', icon: CloseIcon }] : MenuItems
    }, [isMobile])

    return (
        <div style={{ height: '100%' }}>
            <List>
                {menus.map((item) => (
                    <ListItem key={item.label} disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            sx={{
                                fontFamily: 'Montserrat',
                                height: '48px',
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                borderRadius: '5px',
                                marginLeft: '8px',
                                marginRight: '8px',
                                backgroundColor: selectedMenu === item.label ? 'rgba(159, 197, 64, 0.5)' : '#0C1B2A',
                                '&:hover': {
                                    backgroundColor:
                                        selectedMenu === item.label
                                            ? 'rgba(159, 197, 64, 0.5)'
                                            : lighten(0.1, '#0C1B2A'),
                                },
                            }}
                            onClick={() => {
                                handleClickMenuItem(item)
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    fontFamily: 'Montserrat',
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                    color: '#fff',
                                }}
                            >
                                <img src={item.icon} alt={item.label} />
                            </ListItemIcon>
                            <ListItemText
                                primary={item.label}
                                sx={{
                                    opacity: open ? 1 : 0,
                                    fontFamily: 'Montserrat',
                                    color: '#FFFFFE',
                                    fontSize: '16px',
                                    fontWeight: 400,
                                }}
                            />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </div>
    )
}

export default MainMenu
