import { all } from '@redux-saga/core/effects'

import authenticationSaga from '@authentication/store/authentication.saga'
import DashboardSaga from '@dashboard/store/Dashboard.saga'

function* rootSaga(): any {
    yield all([...authenticationSaga, ...DashboardSaga])
}



export default rootSaga
