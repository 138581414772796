import { PUT } from '@api/ApiService'
// import { BatchUpdateParams } from '@dashboard/store/BatchUpdateService/BatchUpdateService.types'
const BASE_URL = process.env.REACT_APP_BASE_API_URL
// const BASE_URL = 'https://nsldev.nimble.expert/VVAPI'

const urlFactory = {
    postBatchUpdateData: (id: number) => `${BASE_URL}/api/SIMI/UpdateStatus?id=${id}`,
}

const BatchUpdateService = {
    postBatchupdateData: async (id: number) => {
        return PUT(urlFactory.postBatchUpdateData(id), {})
    },
}
export default BatchUpdateService
