import { GET } from '@api/ApiService'
import { PlanningStatsParams } from '@dashboard/store/planningStats/PlanningStats.types'
const BASE_URL = process.env.REACT_APP_BASE_API_URL

const urlFactory = {
    getPlanningStatsData: (sendParams: PlanningStatsParams) =>
        `${BASE_URL}/api/SIMI/Batch/${sendParams.batchId}/Stats`,
    getPlanningStatusData: (sendParams: PlanningStatsParams) => `${BASE_URL}/api/SIMI/${sendParams.batchId}/Status`,
}

const PlanningStatsService = {
    getPlanningStatsData: async (sendParams: PlanningStatsParams) => {
        return GET(urlFactory.getPlanningStatsData(sendParams))
    },

    getPlanningStatusData: async (sendParams: PlanningStatsParams) => {
        return GET(urlFactory.getPlanningStatusData(sendParams))
    },
}

export default PlanningStatsService
