import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ParametersParams,ParametersResponse,ParametersTypes } from './Parameters.types'

const initstage: ParametersTypes = {

    ParametersLoading: false,
    ParametersData: null,
    ParametersError: null,

}

const ParametersSlice = createSlice({
    name: 'ParametersSlice',
    initialState: initstage,
    reducers: {
        getParametersData(state, action: PayloadAction<ParametersParams>) {
            state.ParametersLoading = true
            state.ParametersError = null
        },
        getParametersSuccess(state, action: PayloadAction<ParametersResponse[]>) {
            state.ParametersData = action.payload
            state.ParametersLoading = false
            state.ParametersError = null
        },
        getParametersFailure(state, action: PayloadAction<any>) {},
    },
})
export const { getParametersData, getParametersSuccess, getParametersFailure } = ParametersSlice.actions
export default ParametersSlice.reducer
