import { GET } from '@api/ApiService'
import { VersionsParams } from '@dashboard/store/versions/Versions.types'

const BASE_URL = process.env.REACT_APP_BASE_API_URL

const urlFactory = {
    getVersionsData: (sendParams: VersionsParams) =>
        `${BASE_URL}/api/SIMI/Batch/Versions?date=${sendParams.date}&productionType=${sendParams?.productionType}`,
}

const VersionsService = {
    getVersionsData: async (sendParams: VersionsParams) => {
        return GET(urlFactory.getVersionsData(sendParams))
    },
}

export default VersionsService
