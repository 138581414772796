import { GET, POST } from '@api/ApiService'
const BASE_URL = process.env.REACT_APP_BASE_API_URL
// const BASE_URL = "https://nsldev.nimble.expert/VVAPI"

const urlFactory = {
    getBatchData: (productionType: number) => `${BASE_URL}/api/SIMI/Batch?ProductionType=${productionType}`,
    postBatchData: () => `${BASE_URL}/api/SIMI/Batch/`,
}

const BatchService = {
    getBatchData: async (productionType: number) => {
        return GET(urlFactory.getBatchData(productionType))
    },
    postBatchData: async (data: any): Promise<Response> => {
        return POST(urlFactory.postBatchData(), data)
    },
}
export default BatchService
