import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { PlanningStatsTypes, PlanningStatsParams } from './PlanningStats.types'
import { PlanningStatsResponse } from '@app-types/PlanningStatsResponse.types'

const initstage: PlanningStatsTypes = {
    PlanningStatsLoading: false,
    PlanningStatssData: null,
    PlanningStatsError: null,

    planningStatusLoading: false,
    planningStatusData: 0,
    planninhStatusError: null,
}

const PlanningStatsSlice = createSlice({
    name: 'PlanningStatsSlice',
    initialState: initstage,
    reducers: {
        getPlanningStatsData(state, action: PayloadAction<PlanningStatsParams>) {
            state.PlanningStatsLoading = true
            state.PlanningStatsError = null
        },
        getPlanningStatsSuccess(state, action: PayloadAction<PlanningStatsResponse>) {
            state.PlanningStatssData = action.payload
            state.PlanningStatsLoading = false
            state.PlanningStatsError = null
        },
        getPlanningStatsFailure(state, action: PayloadAction<any>) {},

        getPlanningStatusData(state, action: PayloadAction<PlanningStatsParams>) {
            state.planningStatusLoading = true
            state.planninhStatusError = null
        },
        getPlanningStatusSuccess(state, action: PayloadAction<number>) {
            state.planningStatusData = action.payload
            state.planningStatusLoading = false
            state.planninhStatusError = null
        },
        getPlanningStatusFailure(state, action: PayloadAction<any>) {
            state.planningStatusLoading = false
            state.planninhStatusError = action.payload
        },
    },
})
export const {
    getPlanningStatsData,
    getPlanningStatsSuccess,
    getPlanningStatsFailure,
    getPlanningStatusData,
    getPlanningStatusFailure,
    getPlanningStatusSuccess,
} = PlanningStatsSlice.actions
export default PlanningStatsSlice.reducer
