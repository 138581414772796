import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

import App from './app/App'
import { store } from '@store/store'
import baseTheme from './theme'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <Provider store={store}>
        <ThemeProvider theme={baseTheme}>
            <CssBaseline />
            <App />
        </ThemeProvider>
    </Provider>
)

reportWebVitals()
