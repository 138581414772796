import { GET } from '@api/ApiService'
import { PlanningOverviewParams } from '@dashboard/store/planningOverview/PlanningOverview.types'
const BASE_URL = process.env.REACT_APP_BASE_API_URL
// const BASE_URL = "https://nsldev.nimble.expert/VVAPI"

const urlFactory = {
    getPlanningOverviewData: (sendParams: PlanningOverviewParams) =>
        `${BASE_URL}/api/SIMI/Batch/${sendParams.batchId}/SIMIOutput?search=${sendParams.search}`,

    getPlanningKameleonOverviewData: (sendParams: PlanningOverviewParams) =>
        `${BASE_URL}/api/SIMI/Batch/${sendParams.batchId}/KameleonOutput?search=${sendParams.search}`,

    getPlanningStrookOverviewData: (sendParams: PlanningOverviewParams) =>
        `${BASE_URL}/api/SIMI/Batch/${sendParams.batchId}/KameleonStrookOutput?search=${sendParams.search}`,
}

const PlanningOverviewService = {
    getPlanningOverviewData: async (sendParams: any) => {
        return GET(urlFactory.getPlanningOverviewData(sendParams))
    },

    getPlanningKameleonOverviewData: async (sendParams: any) => {
        return GET(urlFactory.getPlanningKameleonOverviewData(sendParams))
    },

    getPlanningStrookOverviewData: async (sendParams: any) => {
        return GET(urlFactory.getPlanningStrookOverviewData(sendParams))
    },
}

export default PlanningOverviewService
