import { createTheme } from '@mui/material/styles'

export const colors = {
    primary: '#354968',
    inProgress: '#EC9E29',
    danger: '#EC4C29',
    success: '#B4D74B',
    dark: '#0C1B2A',
    lightDark: '#383838',
    light: '#FFFFFE',
    notStarted: '#B7B8BA',
    light3: '#6F7175',
    lightDark1: '#11151B',
    lightBlue: '#5989C0',
    lightGreen: '#738d41',
    lightGrey: '#50606B',
    lightGrey1: '#9A9FA5',
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#0C1B2A',
        },
        secondary: {
            main: '#FCFCFC',
        },
    },
    typography: {
        h1: {
            fontSize: '26px ',
            lineHeight: '42.64px ',
            fontFamily: 'Montserrat ',
            fontWeight: '700 ',
        },
        h2: {
            fontSize: '22px',
            lineHeight: '36.08px',
            fontFamily: 'Montserrat',
            fontWeight: '700',
        },

        h3: {
            fontSize: '20px',
            lineHeight: '32.8px',
            fontFamily: 'Montserrat',
            fontWeight: '600',
        },
        h4: {
            fontSize: '18px',
            lineHeight: '29.52px',
            fontFamily: 'Montserrat',
            fontWeight: '600',
        },

        h5: {
            fontFamily: 'Montserrat',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '140%',
        },
        h6: {
            fontFamily: 'Montserrat',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '26.24px',
        },
        subtitle1: {
            fontSize: '16px ',
            lineHeight: '16px ',
            fontFamily: 'Montserrat',
            fontWeight: '400 ',
        },
        subtitle2: {
            fontSize: '14px ',
            lineHeight: '19.6px ',
            fontFamily: 'Montserrat',
            fontWeight: '400 ',
        },
    },
})

export default theme
