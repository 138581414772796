import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects'
import { AnyAction } from 'redux'

import {
    getPlanningStatsData,
    getPlanningStatsSuccess,
    getPlanningStatsFailure,
    getPlanningStatusData,
    getPlanningStatusSuccess,
    getPlanningStatusFailure,
} from './planningStats/PlanningStats.slice'
import { PlanningStatsResponse } from '@app-types/PlanningStatsResponse.types'
import PlanningStatsService from '@api/PlanningStatsService'

import {
    getPlanningOverviewData,
    getPlanningOverviewSuccess,
    getPlanningOverviewFailure,
} from './planningOverview/PlanningOverview.slice'
import { PlanningOverviewResponse } from '@app-types/PlanningOverviewResponse.types'
import PlanningOverviewService from '@api/PlanningOverviewService'

import { getExcelData, getExcelSuccess, getExcelFailure } from './excelFile/ExcelFile.slice'
import ExcelService from '@api/ExcelService'

import {
    getBatchData,
    getBatchSuccess,
    getBatchFailure,
    postBatchSuccess,
    postBatchFailure,
    postBatchData,
} from './BatchService/BatchService.slice'
import BatchService from '@api/BatchService'
import { DataItemResponse } from './BatchService/BatchService.types'
import { getVersionsData, getVersionsSuccess, getVersionsFailure } from './versions/Versions.slice'
import VersionsService from '@api/VersionsService'
import { VersionsResponse } from '@app-types/VersionsResponse.types'

import {
    postBatchUpdateData,
    postBatchUpdateSuccess,
    postBatchUpdateFailure,
} from './BatchUpdateService/BatchUpdateService.slice'
import BatchUpdateService from '@api/BatchUpdateService'

import { getParametersData, getParametersFailure, getParametersSuccess } from './Parameters/Parameters.slice'
import { ParametersResponse } from './Parameters/Parameters.types'
import ParametersService from '@api/ParametersService'

function* watchVersionsData(
    action: ReturnType<typeof getVersionsData>
): Generator<CallEffect<VersionsResponse[]> | PutEffect<AnyAction>, void, VersionsResponse[]> {
    try {
        const params = action.payload
        const response = yield call(VersionsService.getVersionsData, params)

        yield put(getVersionsSuccess(response))
    } catch (error) {
        yield put(getVersionsFailure(error))
    }
}

function* watchExcelData(
    action: ReturnType<typeof getExcelData>
): Generator<CallEffect<File> | PutEffect<AnyAction>, void, File> {
    try {
        const { batchId, productionType, selectedTab } = action.payload
        if (productionType === 'simi') {
            const response = yield call(ExcelService.getExcelData, batchId)
            yield put(getExcelSuccess(response))
        } else if (selectedTab === 1) {
            const response = yield call(ExcelService.getKameleonData, batchId)
            yield put(getExcelSuccess(response))
        } else {
            const response = yield call(ExcelService.getStrookData, batchId)
            yield put(getExcelSuccess(response))
        }
    } catch (error) {
        yield put(getExcelFailure(error))
    }
}

function* watchPostBatchData(
    action: ReturnType<typeof postBatchData>
): Generator<CallEffect<any> | PutEffect<AnyAction>, void, any> {
    try {
        const response = yield call(BatchService.postBatchData, action.payload)

        yield put(postBatchSuccess(response))
    } catch (error) {
        yield put(postBatchFailure(error))
    }
}

function* watchPostBatchUpdateData(
    action: ReturnType<typeof postBatchUpdateData>
): Generator<CallEffect<number> | PutEffect<AnyAction>, void, any> {
    try {
        // const params = action.payload
        const response = yield call(BatchUpdateService.postBatchupdateData, action.payload)

        yield put(postBatchUpdateSuccess(response))
    } catch (error) {
        yield put(postBatchUpdateFailure(error))
    }
}

function* watchParametersData(
    action: ReturnType<typeof getParametersData>
): Generator<CallEffect<ParametersResponse[]> | PutEffect<AnyAction>, void, ParametersResponse[]> {
    try {
        const params = action.payload
        const response = yield call(ParametersService.ParametersData, params)

        yield put(getParametersSuccess(response))
    } catch (error) {
        yield put(getParametersFailure(error))
    }
}

function* watchBatchData(
    action: ReturnType<typeof getBatchData>
): Generator<CallEffect<DataItemResponse> | PutEffect<AnyAction>, void, any> {
    try {
        const response = yield call(BatchService.getBatchData, action.payload)

        yield put(getBatchSuccess(response))
    } catch (error) {
        yield put(getBatchFailure(error))
    }
}

function* watchgetPlanningStatsData(
    action: ReturnType<typeof getPlanningStatsData>
): Generator<CallEffect<PlanningStatsResponse> | PutEffect<AnyAction>, void, PlanningStatsResponse> {
    try {
        const params = action.payload
        const response = yield call(PlanningStatsService.getPlanningStatsData, params)

        yield put(getPlanningStatsSuccess(response))
    } catch (error) {
        yield put(getPlanningStatsFailure(error))
    }
}

function* watchgetPlanningOverviewData(
    action: ReturnType<typeof getPlanningOverviewData>
): Generator<CallEffect<PlanningOverviewResponse[]> | PutEffect<AnyAction>, void, PlanningOverviewResponse> {
    try {
        const { productionType, tabSelected, ...params } = action.payload
        if (productionType === 1) {
            const response = yield call(PlanningOverviewService.getPlanningOverviewData, params)
            yield put(getPlanningOverviewSuccess(response))
        } else if (tabSelected === 1) {
            const response = yield call(PlanningOverviewService.getPlanningKameleonOverviewData, params)
            const Data = {
                ...response,
                simiOutputs: response?.kameleonOutputs,
            }
            yield put(getPlanningOverviewSuccess(Data))
        } else {
            const response = yield call(PlanningOverviewService.getPlanningStrookOverviewData, params)
            const Data = {
                ...response,
                kameleonStrookOutputs: response?.kameleonStrookOutputs,
            }
            yield put(getPlanningOverviewSuccess(Data))
        }
    } catch (error) {
        yield put(getPlanningOverviewFailure(error))
    }
}

function* watchgetPlanningStatusData(
    action: ReturnType<typeof getPlanningStatusData>
): Generator<CallEffect<PlanningStatsResponse> | PutEffect<AnyAction>, void, any> {
    try {
        const params = action.payload
        const response = yield call(PlanningStatsService.getPlanningStatusData, params)

        yield put(getPlanningStatusSuccess(response))
    } catch (error) {
        yield put(getPlanningStatusFailure(error))
    }
}

const DashboardSaga = [
    takeLatest(getPlanningStatsData, watchgetPlanningStatsData),
    takeLatest(getPlanningOverviewData, watchgetPlanningOverviewData),
    takeLatest(getExcelData, watchExcelData),
    takeLatest(getBatchData, watchBatchData),
    takeLatest(postBatchData, watchPostBatchData),
    takeLatest(getVersionsData, watchVersionsData),
    takeLatest(postBatchUpdateData, watchPostBatchUpdateData),
    takeLatest(getParametersData, watchParametersData),
    takeLatest(getPlanningStatusData, watchgetPlanningStatusData),
]

export default DashboardSaga
