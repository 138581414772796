import * as React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import loadable from '@loadable/component'
import Box from '@mui/material/Box'

import PrivateRoutes from './Private.route'
import SSOValidator from '@authentication/components/ssoValidator/SSOValidator'

const LoginPage = React.lazy(async () => await import('@authentication/pages/Login.page'))

const NotFoundPage = loadable(async () => import('@shared/components/notFound/NotFound.component'), {
    resolveComponent: (components) => components.default,
})
const ClientsPage = loadable(async () => import('@dashboard/pages/Dashboard.page'), {
    resolveComponent: (components) => components.default,
})

const PlanningDetails = loadable(async () => import('@dashboard/pages/planningDetails/PlaningDetails'), {
    resolveComponent: (components) => components.default,
})

const Loading: React.FC = () => <p>Loading ...</p>

const BASE_PATH = process.env.REACT_APP_BASE_PATH

const MainRoutes: React.FC = () => {
    return (
        <React.Suspense fallback={<Loading />}>
            <BrowserRouter basename={BASE_PATH}>
                <Box sx={{ display: 'flex' }}>
                    <Routes>
                        <Route element={<SSOValidator />} path="/" />
                        <Route element={<PrivateRoutes />}>
                            <Route element={<ClientsPage />} path="/:simi"></Route>
                            <Route element={<PlanningDetails />} path="/:simi/:BatchId"></Route>
                        </Route>
                        <Route element={<LoginPage />} path="/login" />
                        <Route element={<NotFoundPage />} path="*" />
                    </Routes>
                </Box>
            </BrowserRouter>
        </React.Suspense>
    )
}

export default MainRoutes
