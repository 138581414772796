import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { PlanningOverviewTypes, PlanningOverviewParams } from './PlanningOverview.types'
import { PlanningOverviewResponse } from '@app-types/PlanningOverviewResponse.types'

const initstage: PlanningOverviewTypes = {
    PlanningOverviewLoading: false,
    PlanningOverviewData: [],
    PlanningOverviewError: null,
    PlanningOverviewDataCount: 0,
    PlanningOverviewDataTotalPage: 0,
    PlanningOverviewDataMessage: '',
}

const PlanningOverviewSlice = createSlice({
    name: 'PlanningOverviewSlice',
    initialState: initstage,
    reducers: {
        getPlanningOverviewData(state, action: PayloadAction<PlanningOverviewParams>) {
            state.PlanningOverviewLoading = true
            state.PlanningOverviewError = null
        },
        getPlanningOverviewSuccess(state, action: PayloadAction<PlanningOverviewResponse>) {
            const { count, totalpage, simiOutputs, message, kameleonStrookOutputs } = action.payload
            state.PlanningOverviewDataCount = count
            state.PlanningOverviewData = simiOutputs || kameleonStrookOutputs
            state.PlanningOverviewDataTotalPage = totalpage
            state.PlanningOverviewDataMessage = message
            state.PlanningOverviewLoading = false
            state.PlanningOverviewError = null
        },

        getPlanningOverviewFailure(state, action: PayloadAction<any>) {
            state.PlanningOverviewLoading = false
            state.PlanningOverviewError = action.payload
            state.PlanningOverviewData = []
        },
    },
})
export const { getPlanningOverviewData, getPlanningOverviewSuccess, getPlanningOverviewFailure } =
    PlanningOverviewSlice.actions
export default PlanningOverviewSlice.reducer
