import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import {  BatchUpdateTypes} from './BatchUpdateService.types'


const initstage: BatchUpdateTypes = {
    BatchUpdateLoading: false,
    BatchUpdateData: null,
    BatchUpdateError: null,
}


const BatchUpdateSlice = createSlice({
    name: 'BatchUpdateSlice',
    initialState: initstage,
    reducers: {
        postBatchUpdateData(state, action:PayloadAction<number>) {
            state.BatchUpdateLoading = true
            state.BatchUpdateError = null
        },
        postBatchUpdateSuccess(state, action: PayloadAction<any>) {
            state.BatchUpdateData = action.payload
            state.BatchUpdateLoading = false
            state.BatchUpdateError = null
        },
        postBatchUpdateFailure(state, action: PayloadAction<any>) {},
    },

})
export const { postBatchUpdateData, postBatchUpdateSuccess, postBatchUpdateFailure} = BatchUpdateSlice.actions
export default BatchUpdateSlice.reducer

